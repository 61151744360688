import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { v4 as uuid } from 'uuid';

import TextError from './TextError';

const RadioButtons = (props) => {
  const { label, gif, name, options, isRequired, ...rest } = { ...props };

  const mapOptions = options.map((item) => {
    if (item.value) {
      return item;
    }
    return { value: item, label: item };
  });
  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>
          {label} {isRequired && <span>*</span>}
        </label>
        <ErrorMessage name={name} component={TextError} />
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field id={name} name={name} {...rest}>
        {({ field }) =>
          mapOptions.map((option) => (
            <label key={uuid()} className={`radio radio--${field.value === option.label ? 'checked' : ''}`}>
              {option.label}
              <input
                type="radio"
                id={option.label}
                {...field}
                value={option.label}
                checked={field.value === option.label}
              />
              <span className="checkmark" />
            </label>
          ))
        }
      </Field>
    </div>
  );
};

export default RadioButtons;
