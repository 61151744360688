import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import './FormComponents.scss';

const TextInput = ({ label, gif, name, control, isRequired, ...rest }) => {
  const isBlockType = control === 'block';

  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>
          {label} {isRequired && <span>*</span>}
        </label>
        <ErrorMessage name={name} component={TextError} />
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field
        {...rest}
        type={control === 'numeric' ? 'number' : 'text'}
        id={name}
        name={name}
        className={`input ${isBlockType ? 'field-block' : ''}`}
      />
    </div>
  );
};

TextInput.defaultProps = {
  gif: '',
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default TextInput;
