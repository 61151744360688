import React from 'react';
import PropTypes from 'prop-types';

import { AiOutlineCheckCircle } from 'react-icons/ai';

import Powered from 'components/Surveys/Powered';

const SectionIndicator = ({ groups, completedGroups }) => (
  <section className="section-indicator">
    <div className="section-indicator__sections">
      {(groups || []).map(({ id, groupTitle }) => {
        const isCompleted = [...completedGroups].indexOf(id) !== -1;
        return (
          <div className={`section-indicator__check ${isCompleted ? 'checked' : ''}`} key={id}>
            <p>{groupTitle}</p>
            {isCompleted && (
              <div className="check-icon">
                <AiOutlineCheckCircle />
              </div>
            )}
          </div>
        );
      })}
    </div>
    <Powered />
  </section>
);

SectionIndicator.defaultProps = {
  groups: [],
  completedGroups: [],
};

SectionIndicator.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  completedGroups: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
};

export default SectionIndicator;
