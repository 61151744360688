import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, equals, isNil } from 'ramda';
import { Formik, Form } from 'formik';
import { useTranslation, Trans } from 'react-i18next';

import { validationSchemaBuilder } from 'utils/validationSchema';

import FormControlSurveys from 'components/Surveys/FormControlSurveys';
import SectionIndicator from 'components/Surveys/SectionIndicator';
import Button from 'components/SolidButton';

const Groups = ({ survey, handleSubmit }) => {
  const { title: surveyTitle = '', content: groups = [] } = { ...survey };
  const { t } = useTranslation();

  const [queryGroups, setQueryGroups] = useState(groups);
  const [current, setCurrent] = useState(0);
  const [completedGroups, setCompletedGroups] = useState([]);

  useEffect(() => {
    if (current === queryGroups.length && !equals(groups, queryGroups)) {
      handleSubmit(queryGroups);
    }
  }, [current, queryGroups]);

  const initialFormState = (id) => {
    const findGroupToValidate = queryGroups.find(({ id: validateId }) => validateId === id);
    const initial = {};
    findGroupToValidate.questions.forEach((question) => {
      const { id: questionId = '', type = 'text', label = '', value = '' } = { ...question };
      switch (type) {
        case 'verification':
          initial[questionId] = false || value;
          break;
        case 'block':
          initial[questionId] = label;
          break;
        case 'pointed-multiple-selection':
          initial[questionId] = '' || value.label;
          break;
        default:
          initial[questionId] = '' || value;
      }
    });
    if (findGroupToValidate.files) {
      findGroupToValidate.files.forEach(({ file, questionId }) => {
        initial[questionId] = file;
      });
    }
    return initial;
  };

  const getValue = ({ id: valueId, options, values }) => {
    if (Array.isArray(values[valueId])) {
      return values[valueId].join(', ');
    }
    if (['object'].includes(typeof options[0])) {
      return options.find(({ label }) => label === values[valueId]) || '';
    }
    return isNil(values[valueId]) ? '' : values[valueId];
  };

  const handleAddGroup = (values, groupId) => {
    const currentGroup = groups.find(({ id: queryGroupId }) => queryGroupId === groupId);
    setQueryGroups((prevQueryGroups) =>
      prevQueryGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            questions: group.questions
              .filter(({ type }) => type !== 'file')
              .map((question) => ({
                ...question,
                value: getValue({ id: question.id, options: question.options || [], values }),
              })),
            files: currentGroup.questions
              .filter(({ type, id }) => type === 'file' && !isEmpty(values[id]) && values[id] !== undefined)
              .map(({ id: fileId, label }) => ({
                groupId: group.id,
                label,
                file: values[fileId],
              })),
          };
        }
        return group;
      }),
    );
    setCompletedGroups((prevCompletedGroups) => [...prevCompletedGroups, groupId]);
    setCurrent((prev) => prev + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBack = (id) => {
    setCompletedGroups((prevCompletedGroups) => prevCompletedGroups.filter((selectedGropId) => selectedGropId !== id));
    setCurrent((prev) => prev - 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleAlert = (formikProps) => {
    const { errors } = { ...formikProps };
    const firstError = document.querySelector('.validation-message');
    if (isEmpty(errors) || !firstError) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    } else {
      setTimeout(() => {
        firstError.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  return (
    <>
      <section className={`sections ${groups.length < 2 ? 'full-witdh-sections' : ''}`}>
        <div className="sections__title">
          <h2>{surveyTitle}</h2>
        </div>
        {groups.map((group, groupIndex) => {
          const { id = '', groupTitle = '', questions = [] } = { ...group };
          const initialValues = initialFormState(id);
          const validationSchema = validationSchemaBuilder(questions);
          const submitButtonMessage = id === queryGroups[queryGroups.length - 1].id;

          if (groupIndex !== current) return null;

          return (
            <section key={id} className="form-wrapper">
              <h2 className="sections__form_count">
                <Trans values={{ current: groupIndex + 1, length: groups.length }}>surveys.survey.section</Trans>
              </h2>
              {groupTitle !== 'single' && <h2 className="sections__form_title">{groupTitle}</h2>}
              <div className="sections__form">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={(values) => handleAddGroup(values, id)}
                >
                  {(formikProps) => (
                    <Form>
                      {questions.map((question, index) => {
                        const {
                          type = 'text',
                          label = '',
                          gif = '',
                          id: questionId = '',
                          required = false,
                          includeNA = false,
                          multi = false,
                          options = [],
                          min = 0,
                          max = 10,
                        } = {
                          ...question,
                        };
                        return (
                          <div key={questionId} className="form-input">
                            <div className="current">
                              <div className="current__num">
                                <span>{index + 1}</span>
                              </div>
                              <span className="step" />
                            </div>
                            <FormControlSurveys
                              formikProps={formikProps}
                              control={type}
                              name={questionId}
                              label={label}
                              gif={gif}
                              options={options}
                              isMulti={multi}
                              isRequired={required}
                              includeNA={includeNA}
                              min={Number(min)}
                              max={Number(max)}
                            />
                          </div>
                        );
                      })}
                      <div className="handle-sections">
                        {current > 0 && (
                          <Button
                            type="button"
                            className="secondary"
                            onClick={() => handleBack(groups[groupIndex - 1].id)}
                          >
                            {t('button.back')}
                          </Button>
                        )}
                        <Button
                          onClick={() => handleAlert(formikProps)}
                          type="submit"
                          disabled={formikProps.isSubmitting}
                        >
                          {submitButtonMessage ? t('button.send') : t('button.next')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </section>
          );
        })}
      </section>
      {groups.length > 1 && <SectionIndicator groups={groups} completedGroups={completedGroups} />}
    </>
  );
};

Groups.defaultProps = {
  survey: {},
  handleSubmit: () => {},
};

Groups.propTypes = {
  survey: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  }),
  handleSubmit: PropTypes.func,
};

export default Groups;
