import React from 'react';
import { isEmpty } from 'ramda';
import { Field, ErrorMessage } from 'formik';
import { v4 as uuid } from 'uuid';

import TextError from './TextError';

const CheckboxGroup = (props) => {
  const { label, gif, name, options, isRequired, ...rest } = { ...props };
  const mapOptions = options.map((item) => {
    if (item.value) {
      return item;
    }
    return { value: item, label: item };
  });
  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>
          {label}
          {isRequired && <span>*</span>}
        </label>
        <ErrorMessage name={name} component={TextError} />
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field id={name} name={name} {...rest}>
        {({ field, form }) => {
          if (isEmpty(options)) {
            return (
              <label className={`radio radio--${field.value ? 'checked' : ''}`}>
                <input
                  id={name}
                  type="checkbox"
                  checked={field.value}
                  onChange={() => {
                    form.setFieldValue(name, !field.value);
                  }}
                />
                <span className="checkmark-box" />
              </label>
            );
          }
          return (
            <>
              {mapOptions.map((option) => (
                <label key={uuid()} className={`radio radio--${field.value.includes(option.value) && 'checked'}`}>
                  {option.label}
                  <input
                    type="checkbox"
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                  />
                  <span className="checkmark-box" />
                </label>
              ))}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default CheckboxGroup;
