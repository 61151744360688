import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { loadStripe } from '@stripe/stripe-js';
import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { currencyFormat as formatter } from 'utils/string';
import { CHECK_COUPON_WEB } from 'apollo/queries';
import { CREATE_EVIE_PROGRAM_PAYMENT } from 'apollo/mutations';
import logo from 'assets/images/evie-logo.svg';
import check from 'assets/images/evie-check.svg';
import banner from 'assets/images/evie-banner.svg'
import paymentSuccess from 'assets/images/evie-payment-success.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './evie-checkout.scss';
import FacebookPixel from 'components/FacebookPixel';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const translations = {
  es: {
    customer: 'Tus Datos',
    email: 'Correo Electrónico',
    name: 'Nombre',
    phone: 'Telefóno',
    payment: 'Realizar Pago',
    currency: 'Divisa',
    billingCycle: 'Ciclo de Facturación',
    installments: 'Cuotas',
    monthly: 'Mensual',
    totalMonthly: '/ Mes',
    monthlySuffix: 'por mes',
    yearly: 'Anual',
    yearlySuffix: 'ahorra 25%',
    couponCode: 'Cupón',
    paymentMethod: 'Método de Pago',
    apply: 'Aplicar',
    clear: 'Limpiar',
    couponError: '¡Ups! Parece que ese código promocional no es correcto. Por favor, revísalo e inténtalo de nuevo.',
    subscribe: 'Subscribir',
    disclaimer: 'Tus datos personales se utilizarán para procesar tu pedido, apoyar tu experiencia en este sitio web y para otros fines descritos en nuestra política de privacidad.',
    terms1: 'He leído y acepto los',
    terms2: 'del sitio web/app. Al marcar esta casilla, confirmas que',
    terms3: 'tienes más de 18 años.',
    and: 'y la',
    tyc: "términos y condiciones",
    privacy: "política de privacidad",
    subscriptionError: 'Error inesperado al crear tu suscripción, inténtalo de nuevo.',
    paymentSuccessTitle: 'Subscripción exitosa',
    paymentSuccessMsg: 'Gracias por tu subscripción. Se enviará un correo electrónico automatizado a tu dirección de correo registrada con tus credenciales de acceso a Evie.',
    paymentSuccessButton: 'Ir al Inicio',
    discount: 'Descuento',
    payNow: 'Pagar Total',
    installment: 'Cuotas de'
  },
  en: {
    customer: 'Customer',
    email: 'Email',
    name: 'Name',
    phone: 'Phone',
    payment: 'Payment',
    currency: 'Currency',
    billingCycle: 'Billing Cycle',
    monthly: 'Monthly',
    totalMonthly: '/ Mo',
    monthlySuffix: 'per month',
    installments: 'Installments',
    yearly: 'Yearly',
    yearlySuffix: 'save 25%',
    couponCode: 'Coupon Code',
    paymentMethod: 'Payment Method',
    apply: 'Apply',
    clear: 'Clear',
    couponError: 'Oops! Looks like that promo code isn\'t quite right. Please check it and try again!',
    subscribe: 'Subscribe',
    disclaimer: 'Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.',
    terms1: 'I have read and agree to the website',
    terms2: '. By checking this box you verify that you are',
    terms3: 'over 18 years of age.',
    and: 'and',
    tyc: "terms and conditions",
    privacy: "privacy policy",
    subscriptionError: "Unexpected error creating your subscription, try again",
    paymentSuccessTitle: 'Your Subscription is Successfull',
    paymentSuccessMsg: 'Thank you for your subscription. An automated email will be sent to your registered email with your Evie access credentials.',
    paymentSuccessButton: 'Go to Home',
    discount: 'Discount',
    payNow: 'Pay Now',
    installment: 'Installments of'
  }
}

const plans = {
  evie: {
    es: {
      title: 'Programa de Evie',
      perks: [
        'Video llamada valoración inicial con fisioterapeuta (45 min)',
        '4 Sesiones privadas de seguimiento y control de 15 minutos',
        'Rutinas de ejercicios en la aplicación que van desde 10 a 20 minutos de duración',
        'Chat privado 24/7 con fisioterapeuta',
        'Ejercicios de Kegel guiados y coordinados por expert@s',
        'Contenido interactivo y educativo: vídeos, audios, artículos y ejercicios prácticos',
        'Acceso a la aplicación durante 6 meses'
      ]
    },
    en: {
      title: 'Evie Program',
      perks: [
        "Initial assessment video call with a physiotherapist (45 min)",
        "4 private follow-up and control session of 15 minutes",
        "Exercise routines on the app ranging from 10 to 20 minutes",
        "Private 24/7 chat with a physiotherapist",
        "Guided Kegel exercises and coordination by experts",
        "Interactive and educational content: videos, audios, articles, and practical exercises",
        "Access to the app for 6 months"
      ]
    }
  },
}


const prices = {
  eur: {
    evie: 150,
  },
  cop: {
    evie: 650000,
  },
}

const products = {
  production: {
    evie: 'prod_Qvwda7Fc4sA6d0',
  },
  development: {
    evie: 'prod_QvwWeDdgALbZIJ',
  }
}
const installments = [2,3];
const envProducts = products[process.env.REACT_APP_ENV] || products.development;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const EvieCheckout = ({ currency, plan, campaign, language }) => {
  const t = translations[language] || translations.es;
  const planData = ((plans[plan] || plans.evie)[language]) || plans.evie.es;
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [coupon, setCoupon] = useState();
  const [paymentData, setPaymentData] = useState({ installments:'1' });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [createEvieProgramPayment] = useMutation(CREATE_EVIE_PROGRAM_PAYMENT);
  const [checkCoupon, { data: couponData = {} }] = useLazyQuery(CHECK_COUPON_WEB);
  const [discount, setDiscount] = useState(0);
  const [success, setSuccess] = useState(false);
  const [country, setCountry] = useState(currency === 'cop'? 'co': 'es');
  const [couponError, setCouponError] = useState(false);
  const { replace = () => {} } = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const price = ((prices[currency] || prices.eur)[plan] || (prices[currency] || prices.eur).evie );
  const total = price - discount;
  const productId = envProducts[plan] || envProducts.evie;

  const currencyFormat = (amount)=> {
    const decimalCount = currency === 'cop'? 0: 2;
    const decimal = currency === 'cop'? ',': '.';
    const thousands = currency === 'cop'? '.': ',';
    const symbol = currency === 'eur' ? '€' : '$'
    return `${symbol}${formatter(amount, decimalCount, decimal, thousands)}`;
  }

  const confirmEmail = () => {
    setPaymentData({...paymentData, email })
  }

  const confirmName = () => {
    setPaymentData({...paymentData, name })
  }

  const confirmPhone = () => {
    setPaymentData({...paymentData, phone })
  }

  const validateCoupon = async() => {
    setCouponError(false);
    if (paymentData.couponCode) {
      setPaymentData({...paymentData, couponCode: undefined });
      setDiscount(0);
    } else {
      await checkCoupon({ variables: { couponId: `${coupon}` } });
    }
  }

  const onChangeInstallments= (e)=> {
    setPaymentData({...paymentData, installments: e.target.value })
  }

  const handlePayment = async () => {

    if(loading || !email || !phone || !name || !stripe || !elements || !checked || !paymentData || !paymentData?.name || !paymentData?.phone || !paymentData?.email || success) {
      return;
    }

    setLoading(true);
    try {

      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const {error: submitError} = await elements.submit();
      if (submitError) {
        console.log(submitError);
        setLoading(false);
        return;
      }

      const result = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: paymentData.name,
            email: paymentData.email,
            phone: paymentData.phone
          },
        },
      });

      if (result?.error) {
        alert(result?.error.message)
      } else {
        if (!!result.paymentMethod.id) {
          const response = await createEvieProgramPayment({
            variables: {
              name: paymentData.name,
              email: paymentData.email,
              phone: paymentData.phone,
              coupon: paymentData.couponCode || null,
              currency,
              installments: paymentData.installments,
              paymentMethodId: result.paymentMethod.id,
              productId,
              plan,
              source: campaign ? 'campaign' : 'organic',
              campaign,
              language
            },
          });

          if (!!response?.data?.createEvieProgramPayment?.success) {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
            // setSuccess(true);
            replace(`/checkout-success?lang=${language}`);
          } else {
            alert(response?.data?.createEvieProgramPayment?.errors[0])
          }
        }
      }
    }catch(err) {
      console.log(err)
      alert(t.subscriptionError)
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `${planData?.title} - Checkout`;
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', 'evie-favicon.png');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function onGeoSuccess(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    fetch(`http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=8ad91b1af19a2bdfb7ae0e9589f54d57`).then((data)=>{
     return data.json()
    }).then((data)=>{
      const result = (data ||[])[0];
      const { country: location = 'EE' } = {...result};
      setCountry(`${location}`.toLowerCase())
    }).catch((error)=>{
      console.log(error);
    })
  }

  function onGeoError() {
    console.log("Unable to retrieve your location");
  }

  useEffect(()=>{
    document.body.style.overflow = success?'hidden': 'unset';
  },[success])

  useEffect(()=>{
    if((couponData?.checkStripeCouponWeb?.errors?.length || 0) === 0 && couponData?.checkStripeCouponWeb?.coupon?.valid) {
      setCoupon(couponData?.checkStripeCouponWeb?.coupon?.id);
      setDiscount(couponData?.checkStripeCouponWeb?.coupon?.amount_off ? couponData?.checkStripeCouponWeb?.coupon?.amount_off : price*(couponData?.checkStripeCouponWeb?.coupon?.percent_off/100));
      setPaymentData({...paymentData, couponCode: couponData?.checkStripeCouponWeb?.coupon?.id});
    } else if (coupon) {
      setCoupon('')
      setCouponError(true);
    }
  }, [couponData?.checkStripeCouponWeb?.coupon?.id, couponData?.checkStripeCouponWeb?.errors?.join("-")])

  return (
    <>
     <FacebookPixel pixelId={"570488783823891"}/>
     <Loader
        type="ThreeDots"
        color="#9B2C51"
        height={100}
        width={100}
        className="spinner"
        visible={loading}
      />
      {success && <div className={'success-modal-overlay'}>
        <div className={'success-modal-container'}>
        <img className={'success-modal-image'} src={paymentSuccess} alt="success" />
          <p className={'succes-modal-title'}>{t.paymentSuccessTitle}</p>
          <p className={'succes-modal-message'}>{t.paymentSuccessMsg}</p>
          <button className={'evie-button small'} onClick={()=>{window.location.replace('https://www.hievie.com')}} >
            {t.paymentSuccessButton}
          </button>
        </div>
      </div>}
    <div className={'evie-main-container'}>
      <div className={'evie-checkout-container'}>
        <div className={'evie-card evie-main-card'}>
          <img src={logo} className={'evie-logo'}/>
          <div className={'evie-main-form'}>

            <h2 className={'evie-section-title'}>{t.customer}</h2>
            <p className={'evie-label'}>{t.email}:</p>
            <input type={'email'} className={'evie-input email-input'} value={email} onChange={(e)=>setEmail(e.target.value)} onBlur={confirmEmail} autoComplete="on"/>

            <p className={'evie-label'}>{t.name}:</p>
            <input type={'text'} name="name" id="name" className={'evie-input email-input'} value={name} onChange={(e)=>setName(e.target.value)} onBlur={confirmName} autoComplete="on"/>

            <p className={'evie-label'}>{t.phone}:</p>
            <PhoneInput
              country={country}
              value={phone}
              onChange={(value)=>{setPhone(value)}}
              onBlur={()=>{confirmPhone()}}
              containerStyle={{width: '100%'}}
              inputStyle={{width: '100%', border: '1px solid #818181', paddingTop: '0.5rem',  paddingBottom: '0.5rem'}}
              inputClass={'evie-input'}
            />
            <br/>
            <br/>

            <h2 className={'evie-section-title'}>{t.payment}</h2>
            <div className={`evie-payment-data-container`}>

              <p className={'evie-label'}>{t.currency}:</p>
              <select className={'evie-input evie-payment-type'} value={currency} disabled={true}>
                <option value={'usd'}>United States Dollar (USD)</option>
                <option value={'eur'}>Euro (EUR)</option>
                <option value={'cop'}>Peso Colombiano (COP)</option>
              </select>


              <p className={'evie-label'}>{t.installments}:</p>
              <select className={'evie-input evie-payment-type'} value={paymentData?.installments}
                      onChange={(e) => onChangeInstallments(e)}>
                <option value={'1'}>{t.payNow} ({currencyFormat(total)})</option>
                {installments.map((i) => {
                  return (<option value={`${i}`} key={`${i}`}>{i} {t.installment} ({currencyFormat(total / i)}/mo)</option>);
                })}
              </select>


              <p className={'evie-label'}>{t.couponCode}:</p>
              <div className={'evie-email-confirmed'}>
                {paymentData?.couponCode ? <h3>{paymentData?.couponCode}</h3> :
                  <input type={'text'} className={'evie-input inline'} value={coupon}
                         onChange={(e) => setCoupon(e.target.value)} />}
                <button className={'evie-button small outline'} onClick={validateCoupon}
                        disabled={!paymentData?.couponCode && !coupon}>
                  {paymentData?.couponCode ? t.clear : t.apply}
                </button>
              </div>
            </div>
            {couponError && <p className={'evie-promo-code-error'}>{t.couponError}</p>}
            <br />
            <p className={'evie-label'}>{t.paymentMethod}:</p>
            <br />
            <PaymentElement id="payment-element" />
          </div>
        </div>
        <div className={'evie-card evie-right-card'}>
          <p className={'evie-program-name'}>{planData.title}</p>
          <img src={banner} className={'evie-banner'} />
          {planData.perks.map((perk => (
            <div className={'evie-list-item'}>
              <img src={check} className={'evie-check'} />
              <p className={'evie-paragraph'}>{perk}.</p>
            </div>
          )))}

          <hr />
          <div className={'evie-price-item'}>
            <p className={'evie-paragraph name'}>Subtotal</p>
            <p className={'evie-paragraph value'}>{currencyFormat(price)}</p>
          </div>
          {paymentData?.couponCode && !!discount && <div className={'evie-price-item'}>
              <p className={'evie-paragraph name'}>{t.discount} <span className='discount-code'> ({paymentData?.couponCode})</span></p>
              <p className={'evie-paragraph value'}>-{currencyFormat(discount)}</p>
            </div>}
          <hr/>
            <div className={'evie-price-item total'}>
              <p className={'evie-paragraph name'}>Total</p>
              <p className={'evie-paragraph value'}><strong>{currencyFormat(total)}</strong></p>
            </div>
          <p className={'evie-light-paragraph'}>{t.disclaimer}</p>
          <div className={'evie-check-item'}>
            <input type="checkbox" defaultChecked={checked} onChange={() => setChecked((state) => !state)} className={'evie-checkbox'}/>
            <p className={'evie-paragraph'}>{t.terms1} <a href="https://www.iubenda.com/terms-and-conditions/83039977" target={'_blank'}> {t.tyc}</a> {t.and} <a href="https://www.iubenda.com/privacy-policy/83039977" target={'_blank'}>{t.privacy}</a>{t.terms2}<strong> {t.terms3}</strong></p>
          </div>
        <button disabled={loading || !email || !phone || !name || !stripe || !elements || !checked || !paymentData || !paymentData?.name || !paymentData?.phone || !paymentData?.email || success || !planData} id="submit" className={'evie-button'} type='button' onClick={handlePayment}>{t.subscribe}</button>
        </div>
      </div>
    </div>
    </>
  );
};


const EvieCheckoutWrapper = () => {
  const params = useQuery();
  const plan = params.get("plan");
  const campaign = params.get("source");
  const lang = params.get("lang") || 'es';
  const currency = params.get("currency") || 'eur';

  return (
    <Elements stripe={stripePromise} options={{ paymentMethodCreation: 'manual', mode: 'setup', currency, locale: lang }}>
      <EvieCheckout currency={currency} plan={plan} campaign={campaign} language={lang} />
    </Elements>
  )
}

export default EvieCheckoutWrapper;
