import React, { useEffect } from 'react';
import Select from 'react-select';

import { customStyles } from 'components/FormComponents/MultiSelect';
import BaseForm from './BaseForm';
import TextForm from './TextForm';
import ListForm from './ListForm';
import ScaleForm from './ScaleForm';
import FormFooter from './FormFooter';

const FieldForm = (props) => {
  const { onCancel, onSave, data, groupsList, selectGroup, surveyType, group } = { ...props };
  const { type: typeObj = {} } = { ...data };
  const { type = '', title = '' } = { ...typeObj };

  useEffect(() => {
    if (data.groupId) {
      selectGroup(data.groupId);
    }
  }, [data.groupId]);

  const getGroupOptions = (groups = []) => groups.map(({ groupTitle, id }) => ({ label: groupTitle, value: id }));

  const showSelectGroups = surveyType === 'grouped' && !data.groupId;
  return (
    <>
      <h2 className="title">Configurar pregunta</h2>
      {showSelectGroups && (
        <>
          <label htmlFor="select-group">Grupo</label>
          <Select
            id="select-group"
            placeholder="Selecciona un grupo para la pregunta"
            options={getGroupOptions(groupsList)}
            styles={customStyles}
            onChange={({ value }) => selectGroup(value)}
          />
          <br />
        </>
      )}
      <p className="description">
        <b>Tipo: </b>
        {title}
      </p>
      <br />
      {['numeric', 'verification', 'date', 'cie10', 'file'].includes(type) && (
        <BaseForm
          data={data}
          onUpdateField={onSave}
          footer={<FormFooter group={group} survey={surveyType} onCancel={onCancel} />}
        />
      )}
      {['text', 'block'].includes(type) && (
        <TextForm
          data={data}
          onUpdateField={onSave}
          footer={<FormFooter group={group} survey={surveyType} onCancel={onCancel} />}
        />
      )}
      {['multiple-selection', 'pointed-multiple-selection'].includes(type) && (
        <ListForm
          data={data}
          onUpdateField={onSave}
          footer={<FormFooter group={group} survey={surveyType} onCancel={onCancel} />}
        />
      )}
      {type === 'numeric-scale' && (
        <ScaleForm
          data={data}
          onUpdateField={onSave}
          footer={<FormFooter group={group} survey={surveyType} onCancel={onCancel} />}
        />
      )}
    </>
  );
};

export default FieldForm;
