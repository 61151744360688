import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import './FormComponents.scss';

const RangeInput = ({ label, gif, name, includeNA, isRequired, ...rest }) => {
  const { min = 0, max = 10 } = { ...rest };
  const length = max - min + 1;
  let range = [...new Array(length)].map((_, index) => index + min);
  if (includeNA) {
    range = ['N/A', ...range];
  }
  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>
          {label} {isRequired && <span>*</span>}
        </label>
        <ErrorMessage name={name} component={TextError} />
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field name={name}>
        {({ field, form }) => (
          <div className="range-container range-surveys">
            {(range || []).map((item) => (
              <div
                aria-hidden="true"
                key={item}
                className={`range-item range-item-surveys ${field.value === item ? 'active-surveys' : ''}`}
                onClick={() => {
                  form.setFieldValue(field.name, item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </Field>
    </div>
  );
};

RangeInput.defaultProps = {
  gif: '',
  includeNA: false,
};

RangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
  includeNA: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
};

export default RangeInput;
