import React, { useState, useRef } from 'react';
import { isEmpty } from 'ramda';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { validationSchemaBuilder } from 'utils/validationSchema';

import FormControlSurveys from 'components/Surveys/FormControlSurveys';
import SectionIndicator from 'components/Surveys/SectionIndicator';
import Button from 'components/SolidButton';
import Modal from 'components/Modal';

const PreviewModal = ({ modalIsOpen, closeModal, form }) => {
  const { t } = useTranslation();
  const modalProps = {
    content: {
      width: '95%',
      maxWidth: '1280px',
      padding: '0',
      overflowY: 'hidden',
    },
  };
  const { title: surveyTitle = '', groups, content } = { ...form };
  const getGroups = groups || content;

  const [queryGroups, setQueryGroups] = useState(getGroups);
  const [current, setCurrent] = useState(0);
  const [completedGroups, setCompletedGroups] = useState([]);
  const validationMessageRef = useRef();

  const initialFormState = (id) => {
    const findGroupToValidate = queryGroups.find(({ id: validateId }) => validateId === id);

    const initial = {};
    (findGroupToValidate && findGroupToValidate.questions ? findGroupToValidate.questions : []).forEach((question) => {
      const { id: questionId = '', type = 'text', label = '', value = '' } = { ...question };
      switch (type) {
        case 'verification':
          initial[questionId] = false || value;
          break;
        case 'block':
          initial[questionId] = label;
          break;
        case 'pointed-multiple-selection':
          initial[questionId] = '' || value.value;
          break;
        default:
          initial[questionId] = '' || value;
      }
    });
    if (findGroupToValidate && findGroupToValidate.files) {
      findGroupToValidate.files.forEach(({ file, questionId }) => {
        initial[questionId] = file;
      });
    }
    return initial;
  };

  const getValue = ({ id: valueId, options, values }) => {
    if (Array.isArray(values[valueId])) {
      return values[valueId].join(', ');
    }
    if (['object'].includes(typeof options[0])) {
      return options.find(({ value }) => value === values[valueId]) || '';
    }
    return values[valueId] || '';
  };

  const handleAddGroup = (values, groupId) => {
    const currentGroup = getGroups.find(({ id: queryGroupId }) => queryGroupId === groupId);
    setQueryGroups((prevQueryGroups) =>
      prevQueryGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            questions: group.questions
              .filter(({ type }) => type !== 'file')
              .map((question) => ({
                ...question,
                value: getValue({ id: question.id, options: question.options || [], values }),
              })),
            files: currentGroup.questions
              .filter(({ type, id }) => type === 'file' && !isEmpty(values[id]) && values[id] !== undefined)
              .map(({ id: fileId, label }) => ({
                groupId: group.id,
                label,
                file: values[fileId],
              })),
          };
        }
        return group;
      }),
    );
    setCompletedGroups((prevCompletedGroups) => [...prevCompletedGroups, groupId]);
    setCurrent((prev) => prev + 1);
    validationMessageRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  };

  const handleBack = (id) => {
    setCompletedGroups((prevCompletedGroups) => prevCompletedGroups.filter((selectedGropId) => selectedGropId !== id));
    setCurrent((prev) => prev - 1);
    validationMessageRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal} closeable={false} modalProps={modalProps}>
      <section className={`survey-preview ${getGroups.length < 2 ? 'full-witdh-survey-preview' : ''}`}>
        <div className="survey-preview__header">
          <h3 className="survey-preview__header--title">{t('forms.previewTitle')}</h3>
        </div>
        <div className="survey-preview__content">
          <section className="sections survey-preview__sections">
            <div className="sections__title" ref={validationMessageRef}>
              <h2>{surveyTitle}</h2>
            </div>
            {getGroups.map((group, groupIndex) => {
              const { id = '', groupTitle = '', questions = [] } = { ...group };
              const initialValues = initialFormState(id);
              const validationSchema = validationSchemaBuilder(questions);
              const submitButtonMessage = id === queryGroups[queryGroups.length - 1].id;

              if (groupIndex !== current) return null;

              return (
                <section key={id} className="form-wrapper">
                  <h2 className="sections__form_count">
                    Sección {groupIndex + 1} de {getGroups.length}
                  </h2>
                  {groupTitle !== 'single' && <h2 className="sections__form_title">{groupTitle}</h2>}
                  <div className="sections__form">
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={initialValues}
                      onSubmit={(values) => handleAddGroup(values, id)}
                    >
                      {(formikProps) => (
                        <Form>
                          {questions.map((question, index) => {
                            const {
                              type,
                              label = '',
                              gif = '',
                              id: questionId = '',
                              required = false,
                              includeNA = false,
                              multi = false,
                              options = [],
                              min = 0,
                              max = 10,
                            } = {
                              ...question,
                            };
                            return (
                              <div key={questionId} className="form-input">
                                <div className="current">
                                  <div className="current__num">
                                    <span>{index + 1}</span>
                                  </div>
                                  <span className="step" />
                                </div>
                                <FormControlSurveys
                                  formikProps={formikProps}
                                  control={type.type || type || 'text'}
                                  name={questionId}
                                  label={label}
                                  gif={gif}
                                  options={options}
                                  isMulti={multi}
                                  isRequired={required}
                                  includeNA={includeNA}
                                  min={Number(min)}
                                  max={Number(max)}
                                />
                              </div>
                            );
                          })}
                          <div className="handle-sections">
                            {current > 0 && (
                              <Button
                                type="button"
                                className="secondary"
                                onClick={() => handleBack(getGroups[groupIndex - 1].id)}
                              >
                                {t('button.back')}
                              </Button>
                            )}
                            <Button type="submit" disabled={submitButtonMessage}>
                              {submitButtonMessage ? t('button.send') : t('button.next')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </section>
              );
            })}
          </section>
        </div>
      </section>
      {getGroups.length > 1 && <SectionIndicator groups={getGroups} completedGroups={completedGroups} />}
    </Modal>
  );
};

export default PreviewModal;
