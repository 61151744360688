import {
  FaChevronCircleDown,
  FaCheckSquare,
  FaFont,
  FaSlidersH,
  FaSortNumericUp,
  FaCalendarDay,
  FaFileUpload,
  FaAlignJustify,
  FaHeartbeat,
} from 'react-icons/fa';

import { GoGraph } from 'react-icons/go';

export const inputTypes = [
  {
    type: 'multiple-selection',
    title: 'Lista desplegable',
    description: 'Listas para selección múltiple o de única respuesta',
    icon: FaChevronCircleDown,
  },
  {
    type: 'pointed-multiple-selection',
    title: 'Lista desplegable con opciones numéricas',
    description: 'Selección multiple con única respuesta, opciones tendrá un valor numérico asignado',
    icon: GoGraph,
  },
  { type: 'text', title: 'Entrada de texto', description: 'Campo para ingresar valores de tipo texto', icon: FaFont },
  {
    type: 'cie10',
    title: 'Diagnóstico CIE10',
    description: 'Campo de selección de patologías',
    icon: FaHeartbeat,
  },
  {
    type: 'numeric',
    title: 'Entrada numérica',
    description: 'Campo para ingresar valores de tipo número',
    icon: FaSortNumericUp,
  },
  {
    type: 'numeric-scale',
    title: 'Escala de valores',
    description: 'Campo para selección de un valor en una escala determinada',
    icon: FaSlidersH,
  },
  {
    type: 'verification',
    title: 'Casilla de verificación',
    description: 'Campo para preguntas de verificación o aprobación',
    icon: FaCheckSquare,
  },
  {
    type: 'date',
    title: 'Fecha',
    description: 'Campo tipo calendario para selección de fechas',
    icon: FaCalendarDay,
  },
  {
    type: 'file',
    title: 'Archivo',
    description: 'Campo para subir imágenes o archivos PDF',
    icon: FaFileUpload,
  },
  {
    type: 'block',
    title: 'Bloque de texto',
    description: 'Bloque de texto informativo para agregar a tu formulario',
    icon: FaAlignJustify,
  },
];

export const typeFormOptions = [
  { label: 'Valoración', value: 'assesment' },
  { label: 'Segumiento', value: 'followup' },
  { label: 'Encuesta', value: 'survey' },
];

export const typeSurveyOptions = [
  { label: 'Simple', value: 'simple' },
  { label: 'Agrupada', value: 'grouped' },
];

export const initialFormTypeValues = {
  title: '',
  typeForm: '',
  surveyType: 'simple',
};
